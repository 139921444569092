var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            staticClass: "elevation-1 mt-5",
            attrs: {
              "item-value": _vm.itemValue,
              "item-text": _vm.itemText,
              search: _vm.companySearch,
              items: this.companies,
              dense: "",
              headers: this.headers,
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "py-3",
                        attrs: { flat: "", height: "auto" },
                      },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "mt-3 mx-3" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mb-3" },
                                      [
                                        _c(
                                          "v-toolbar-title",
                                          {
                                            staticClass:
                                              "font-weight-bold text-h4",
                                          },
                                          [_vm._v(" Company ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-row",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "mx-2",
                                          attrs: {
                                            dense: "",
                                            outlined: "",
                                            clearable: "",
                                            label: "Search",
                                          },
                                          model: {
                                            value: _vm.companySearch,
                                            callback: function ($$v) {
                                              _vm.companySearch = $$v
                                            },
                                            expression: "companySearch",
                                          },
                                        }),
                                        _c("v-select", {
                                          staticClass: "mx-2",
                                          attrs: {
                                            dense: "",
                                            outlined: "",
                                            items: _vm.verificationStatusOption,
                                          },
                                          on: { change: _vm.fetch },
                                          model: {
                                            value: _vm.verificationStatus,
                                            callback: function ($$v) {
                                              _vm.verificationStatus = $$v
                                            },
                                            expression: "verificationStatus",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "red",
                                              plain: "",
                                              outlined: "",
                                            },
                                            on: {
                                              click: _vm.redirectCleanCompany,
                                            },
                                          },
                                          [_vm._v(" Clean company ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isVerificationStatusUpdated
                                  ? _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          type: "success",
                                          dismissible: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Succesfully update company verification status "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.companies.indexOf(item) + 1) + " "),
                  ]
                },
              },
              {
                key: "item.verification",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pt-4 pb-0 mb-0 d-flex justify-center align-center",
                      },
                      [
                        _c("v-select", {
                          staticClass: "pt-0 mb-0 d-flex align-center",
                          attrs: {
                            dense: "",
                            outlined: "",
                            items: _vm.verificationStatusOption,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.updateCompanyVerification(item)
                            },
                          },
                          model: {
                            value: item.verification,
                            callback: function ($$v) {
                              _vm.$set(item, "verification", $$v)
                            },
                            expression: "item.verification",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }